@import '../../assets/sass/abstracts/variables';

.suggestedKeywordComponent {
  .suggested-keyword__group {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

  }
  .keywords {
    background: $white;
    padding: 30px 25px;
    margin-right: 10px;

    &__head {
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      margin-bottom: 25px
    }
  }
  .keyword {
    &__group {
      margin-left: 20px;
    }
  }
  .recommended {
    background: $white;
    padding: 30px 25px;

    &__head {
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      margin-bottom: 25px;
    }
    &__item {
      white-space: pre;
      line-height: 250%;
      &:not(:first-child){
        margin-left: 15px;
      }
    }
    &__group {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 350px;
    }


    &__btn {
      text-align: right;
      .btn {
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        text-decoration: underline
      }
    }
  }
}