@import '../../../assets/sass/abstracts/variables';

.navbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 36px;
  &__left, &__right {
    display: flex;
    align-items: center;
  }
  &__text {
    margin-left: 10px;
    &--head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
    &--text {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  &__account {
    display: flex;
  }
  &__user {
    &--name {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      text-align: left;
      &-group {
        white-space: pre;
        margin-left: 10px;
      }
    }
    &--designation {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      text-align: left;
    }
  }

  &__dropdown {
    text-align: center;

    &--item {
      padding: 10px 10px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
    }
  }


}

.ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
  margin: 10px !important;
}