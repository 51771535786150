@import "../../assets/sass/abstracts/variables";

.filter-review-component {
  .filter {
    background: $white;
    padding: 18px 12px;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-size: 18px;
      line-height: 21px;
      font-weight: 400;

    }
    &__type {
      display: flex;
      justify-content: space-between;
      &--item {
        &:not(:first-child) {
          margin-left: 60px;
        }
        &.selected {
          font-weight: 700;
        }
      }
    }
    &__clear {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      padding-left: 100px;
      margin-right: 100px;
    }
  }

  .form {
    margin-top: 25px;
    &__label {
      display: block;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 10px;
    }
    &__group {
      display: inline-block;
      width: 230px;
      padding: 20px;
      //&:not(:first-child){
      //  padding-left: 20px
      //}
    }
    &__select {
      background: $background;
      width: 100%;
    }
    .ant-select-selection {
      background-color: green;
    }
  }
}