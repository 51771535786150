@import "../../../assets/sass/abstracts/variables";
.sentiment-by-location-page {
  .sbl {
    margin: 10px 0;
    background: $white;
    padding: 30px 25px;
    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }
}