@import "../../assets/sass/abstracts/variables";

.overview {
  .sa {
    background: $white;
    &__text {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      padding: 26px 17px
    }
    &__ratings {
      display: flex;
      justify-content: center;
      align-items: center;
      &--item {
        margin: 10px 25px;
      }
    }
  }
  .top__reviewer {
    margin: 21px 0;
    background: $white;
    &--head {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      padding: 26px 17px 10px;
    }
    .tr {

      &__items {
        padding: 10px 17px 40px;
      }
      &__item {
        padding: 11px;
        display: flex;
        &--text {
          width: 130px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400
        }
        &--rating {

        }
        &--score {

        }
      }
    }
  }
  .report {
    background: white;
    padding: 16px;
    &__head {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__text {
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
      padding-bottom: 96px;
    }
  }
  &__sidebar {
    //background: $white;
    margin: 0 0 0 25px;
  }
  .filter {
    margin-top: 60px;
    &__type {
      display: flex;
      justify-content: space-between;
    }
    &__form {
      margin: 25px 0;
      position: relative;
      &--clear-btn{
        position: absolute;
        top: 0;
        right: 0;
      }
      &--item {
        margin-bottom: 15px;
      }
    }
  }
}