.topItem {
  &__text {
    padding-right: 10px;
  }
}
.toptext {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  &__group {
    &:not(:first-child){
      padding-left: 40px;
    }
    &--text {
      padding-right: 10px;
    }
  }
}

.insights {
  position: relative;
  width: 100%;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px;
    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  .empty-category {
    display: block;
    margin: 150px auto 50px;
    text-align: center;
    &__text {
      font-size: 38px;
      line-height: 45px;
      font-weight: 300;
      margin-bottom: 64px;
      color: rgba(#22282F, 0.7)
    }
    &__image {

    }
  }
}