.setup-page {

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 30px;
    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
    &__button {
      .text-btn {
        padding-left: 40px;
      }
    }
  }

  .status-btn {
    padding: 5px 25px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    border-radius: 15px;
  }


}

.table {
  .pill-btn {
    padding: 9px 0;
    width: 48px;
    text-align: center;
    color: #FFF;
    //color: $white;
    border-radius: 5px;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
  }
  .green {
    background: #3D9C73;
  }
  .faded-green {
    background: rgba(#3D9C73, 0.53);

  }
  .red {
    background: #C6261A;
  }
  .faded-red {
    background: rgba(#C6261A, 0.4);
  }
}