@import "../../../assets/sass/abstracts/variables";

.setup-keyword-page {
  padding: 0 36px;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 30px;

    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  .setup__group {
    padding: 20px 36px 0;
    &--item {

    }
    &--btn {
      margin-top: 25px;
      text-align: right;
      .btn {
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        text-decoration: underline
      }
    }
  }
  .sgi {
    margin-bottom: 40px;
    &__head {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      &--text {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
      &--actions {
        .btn {
          font-size: 16px;
          line-height: 18px;
          font-weight: 700;
          text-decoration: underline;
        }
        .delete-btn {
          color: $red;
          padding-left: 22px;
        }
      }
    }
    &__body {
      background: white;
      padding: 50px 25px 50px;
      &--item {
        background: $black;
        color: $white;
        padding: 5px 25px;
        border-radius: 15px;
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
      &--no-text {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        color: rgba($black, 0.8);
      }
    }
  }


}