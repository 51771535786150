.presence-page {
   .ant-table-wrapper {
      background: #ffffff;
    }

    .ant-table-cell {
      padding: 16px 20px;
    }

 }

.presence__header {
  display: flex;
  align-items: center;
  margin-top: 13px;
  &--main {
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
  }
  &--text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin-left: 40px;
  }
  &--button {
    .btnn {
      padding: 4px 20px;
      margin-left: 40px;
      border-radius: 20px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }
}
