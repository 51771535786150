@import '../assets/sass/abstracts/variables';

.default {
  &__sidebar {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: $white;
    overflow-x: hidden;
  }
  &__children {
    margin: 0 13px;
    overflow-x: scroll;
  }
}