@import "../../../assets/sass/abstracts/variables";

.category-by-performance{
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 36px;
    background: $white;
    margin-bottom: 8px;

    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }




  .table {
    margin-top: 10px;
  }
}