.menu {
  &__item {
    text-align: center;
    &--text {
      padding-left: 10px;
    }
    &.current {
      color: #007ECA;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
.ant-menu-item {
  margin-left: 40px;
  //text-align: center;
}
.ant-menu-vertical
.ant-menu-item:not(:last-child),
.ant-menu-vertical-left
.ant-menu-item:not(:last-child),
.ant-menu-vertical-right
.ant-menu-item:not(:last-child),
.ant-menu-inline
.ant-menu-item:not(:last-child) {
  margin-bottom: 16px;
}