@import '../../assets/sass/abstracts/variables';

.keywords-and-adjectives {
  &.kaa {
    background: $black;
  }

  .kaa {
    display: flex;
    flex-wrap: nowrap;

    &__main {
      width: 100%;
      min-height: 100vh;
      height: 100%;
      background: $white;
      padding: 90px 45px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;

    }

    &__head {
      font-size: 40px;
      line-height: 46px;
      font-weight: 700;
      margin-bottom: 60px;
    }

    &__post-head {
      display: flex;
      justify-content: space-between;

      &--nav {
        display: flex;
        align-items: center;

        &-item {
          font-size: 20px;
          line-height: 23px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);

          &:not(:first-child) {
            margin-left: 40px;
          }

          &.selected {
            color: $white;
            font-weight: 700;
            background: $black;
            padding: 10px 33px;
            border-radius: 10px;
          }

        }
      }

      &--filter {
        display: flex;
        margin-right: 32px;
        align-items: center;

        .form__group {
          width: 125px;
          margin-right: 30px;
        }

        .form__select {
          width: 100%;
        }
      }
    }



    &__keywords {
      margin: 70px 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .keyword {
        min-width: 600px;
        &__groups {
          display: flex;
        }
        &__group {
          padding: 0 20px;
          &--item {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
        .btn {
          margin-top: 80px;
        }
      }

      .analysis {
        margin-top: 25px;
        padding: 10px 20px;
        border-left: 1px solid rgba($black, 0.5);

        &__head {
          font-size: 24px;
          line-height: 28px;
          font-weight: 700;
          margin-bottom: 40px;
        }
        &__text {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          color: rgba($black, 0.6);
        }
        &__item {
          &--group {
            margin: 40px 0 16px;
          }

          display: flex;
          &:not(:last-child){
            margin-bottom: 16px;
          }
          &--text {
            margin-right: 25px;
            width: 75px;
          }
        }
        &__btn {
          .btn {
            text-decoration: underline;
            font-size: 18px;
            line-height: 21px;
            font-weight: 400;
          }
        }
      }
    }

    &__item{
      position: relative;

      .filter {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        min-height: 500px;
        background-color: $white;
        box-shadow: 0px 4px 30px rgba(81, 81, 81, 0.25);

        .form {
          margin-top: 25px;
          &__label {
            display: block;
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          &__group {
            display: inline-block;
            width: 230px;
            padding: 20px;
            //&:not(:first-child){
            //  padding-left: 20px
            //}
          }
          &__select {
            width: 100%;
            &:not(:last-child){
              margin-bottom: 50px;
            }
          }
          &__button {
            text-align: center;
            margin-top: 20px;
          }
        }
      }
    }
  }

}