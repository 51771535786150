.btn {
  text-decoration: none;
  display: inline-block;
  border: none;
  transition: all 0.2s;
  position: relative;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 10px;
  padding: 13px 45px;
  @include respond(tab-land) {
    //padding: 17.25px 50px;
  }

  &:hover {
    //transform: translateY(-0.05rem);
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-0.1rem);
    border: var(--color-black);
  }

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
}

.text-btn {
  padding: 0;
  background: none;
  border: none;
  &:hover {
    transform: translateY(-0.05rem);
    box-shadow: none;
  }
}
.btn-black {
  color: $white;
  background: $black;
}

.btn-rounded {
  border-radius: 5%;
}