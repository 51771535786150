.edit-category-page {
  padding: 0 36px;
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 36px 30px;

    &__head {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }

  .form {
    &__group  {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__label {
      font-size: 16px;
      line-height: 14px;
      font-weight: 400;
      width: 150px;
      text-align: right;
      padding-right: 20px;
    }
    &__input {
      &--name {
        font-weight: 700;
      }
    }
    &__selected {
      display: flex;
      &--item {
        margin-left: 20px;
        &.selected {
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }
  }

}