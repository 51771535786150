$background: #E9E9E9;
$black-main: #000;
$black: #22282f;
$white: #fff;


$purple: #4a57c3;
$green: #61c972;
$dark-grey: #5e5e5e;
$font-base: "Roboto" Sans-serif;
$red: #FD392B;
