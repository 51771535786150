@import '../../assets/sass/abstracts/variables';

.sidebar {
  width: 200px;
  padding-top: 200px;
  background: $black;
  color: $white;
  height: 100%;
  min-height: 100vh;
  &__menu {
    &--item {
      padding: 0 30px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      &:not(:last-child){
        margin-bottom: 50px;
      }
    }
  }
}