.review {
    display: flex;
    flex-direction: column;
    &-container {
        background: #ffffff;
    }

    &-filter_container {
        display: flex;
        justify-content: space-between;
    }

    &-filter {
        display: flex;
        padding-left: 10px;

        &_title {
            display: flex;
            justify-content: space-around;
        }

        &-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #22282F;
        }
    }

    &-dropdown {
        &-container {
            display: flex;
            justify-content: space-around;
        }
        //width: 230px;
        height: 37px;
        background: #E9E9E9;
        border-radius: 5px;
        margin-left: 30px;
        margin-bottom: 22px; 
        padding-left: 11px;
        padding-top: 11px;
        padding-right: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        &-search {
            display: flex;
            margin-right: 3rem;
        }
        &-icon {
            margin-left: 7rem;
        }
    }

    &-item {
        padding-right: 50px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        padding-top: 21px;

        &-cl {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 5rem;
            cursor: pointer;
        }

        &-clear {
             padding-right: 5px;
             padding-top: 21px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #22282F;
            &-icon {
                font-size: 12px;
                padding-top: 10px;
            }
        }
    }
   &-recent {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 35px;
    background: #ffffff;
    padding-top: 21px;
    &-checkbox {
        margin-left: 21px;
    }
   }

   &-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding-top: 19px;
    &-check {
        display: flex;
    }
        &-header {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #22282F;
            margin-left: 21px;
        }
        &-text {
            padding-left: 8px;
        }
   }
   &-location {
        margin-right: 3rem;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #22282F;
   }

   &-message {
        margin-top: 36px;
        margin-left: 46px;
        &-img {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

       &-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;       
        color: #22282F;
        padding-left: 16px;
       }
       &-msg {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #22282F;
        padding-bottom: 19px;
       }
       &-icon {
            display: flex;
            justify-content: space-between;
            &-view {
                padding-right: 30px;
            }
          
            &-wrap {
                display: flex;
                margin-right: 4rem;
            }
       }
   }
}

.ratings {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background: #22282F;
    //width: 58px;
    height: 24px;
    margin-left: 17px;
    &-number {
        color: #fff;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding-left: 16px;
        padding-right: 9px;
        padding-top: 12px;
    }
}

.horizontal {
    opacity: 0.6;
    border: 1px solid rgba(34, 40, 47, 0.6);
    width: 100%;
}

.conversation {
    &-container {
        background: #fff;
        margin-top: 25px;
    }
&-dropdown {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px; 
    padding-top: 40px;
    padding-right: 8px;  
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #22282F;
    opacity: 0.8;
}
&-header {
    display: flex;
    justify-content: flex-start;
    margin-left: 21px;
}
&-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    padding-right: 52px;
    padding-top: 16px;
    color: #22282F;
}
&-wrap {
    display: flex;
    justify-content: space-between;
}
&-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #22282F;
    padding-left: 21px;
    padding-top: 33px;
}
&-chart {
    display: flex;
    justify-content: space-around;
    &-text {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        padding-left: 5rem;
    }
}


}

.ant-typography {
    padding-right: 40px;
}

.ant-table-wrapper {
    margin-left: 25px;
    margin-right: 25px;
}