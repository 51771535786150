@import '../../assets/sass/abstracts/variables';
.reports {

  .chart {
    &-container {
      background: $white;
      margin: 10px 0;
      padding: 18px 20px 100px;
    }

    &__type {
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      color: rgba($black, 0.7);
      .btn {
        &.selected {
          color: $black;
          text-decoration: underline;
          font-weight: 700;
        }
        &:not(:last-child){
          margin-right: 50px;
        }
      }
    }
    &__head {
      margin: 35px 0 25px;
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
    }
  }
}