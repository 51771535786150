.toptext {
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  &__group {
    white-space: pre;
    &:not(:first-child){
      padding-left: 40px;
    }
    &--text {
      padding-right: 10px;
    }
    &--active {
      font-weight: 700;
    }
  }
}