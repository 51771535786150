@import "../../assets/sass/abstracts/variables";


.competitive-performance-by-category {
  .categories {
    background-color: $white;
    margin: 10px 0;
    padding: 15px 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

  }
  .category {
    max-width: 450px;
    padding: 10px 30px 20px;
    &__head {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      &--main {
        font-size: 20px;
        line-height: 24px;
      }
      &--main {
        font-size: 16px;
        line-height: 18px;
      }
    }
    &__hr {
      border-top: 1px solid #000000B2;
      width: 100%;
      margin: 10px 0 20px;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &--name {
        width: 150px;
      }
      &--rating {
        width: 250px;
      }
    }
  }
}