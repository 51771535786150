@import "../../assets/sass/abstracts/variables";


.competitive-ranking {
  .reviews {
    margin-top: 10px;
    background: $white;
    padding: 50px 15px;
    display: flex;
    justify-content: center;
  }
  .review {
    &__item {
      padding: 15px 30px;
      &--head {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      .outer-label {
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
      }
      .inner-label {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
      }
    }
  }
}