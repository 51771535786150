@import "abstracts/variables";
@import "abstracts/mixins";


@import "base/animations";
@import "base/utilities";

@import "components/buttons";


*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  margin: 0 auto;
  background: $background;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  font-size: 1.025em;
  transform: translateY(-0.05rem);
  cursor: pointer;
}
li {
  list-style-type: none;
}
