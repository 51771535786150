@import "../../assets/sass/abstracts/variables";


.competitive-performance-by-category-alt {
  .sentiment-filter {
    background: $white;
    padding: 30px 25px;
    margin: 10px 0;
    display: flex;
    //justify-content: space-evenly;
    flex-wrap: wrap;
    .filter {
      &__types {
        display: flex;
        margin-bottom: 25px;
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
      }
      &__type {
        &--item {
          &:not(:last-child){
            margin-right: 40px;
          }
          &.selected {
            font-weight: 700;
          }
        }
      }
      &__head {
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      &__item {
        display: flex;
        padding-bottom: 16px;
        &--text {
          width: 150px;
        }
      }
    }

    .sentiment {
      max-width: 500px;
      margin: 0 auto;
      flex-grow: 1;
      &__category {
        display: flex;
        &--item {
          &:not(:last-child){
            margin-right: 40px;
          }
          &.selected {
            font-weight: 700;
          }

        }
      }

      .sent {
        margin-top: 52px;
        border-bottom: 1px solid rgba($black-main, 0.7);
        padding-bottom: 55px;
        &__head {
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
        }
        &__address {
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          margin: 8px 0 5px;
        }
        &__date-location {
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          color: rgba($black, 0.8);
        }
        &__review {
          .btn {
            //color: #3D9C73;
            font-weight: 700;
          }
        }
      }
    }
  }

}